import api, { StrapiFile } from './base';

export interface SignupParams {
  fullName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  username?: string;
  password?: string;
  role: ('authenticated' | 'institution' | 'multiple_roles');
  languages?: { name: string; experienceLevel: string; id: number }[];
  language?: string;  
}

export interface ProjectLeadParams {
  organizationName: string;
  organizationNumber: string;
  provience: string;
}

export interface LoginParams {
  identifier: string;
  password: string;
}

export type SendEmailConfirmationParams = {
  email: string;
};

export type ResetPasswordParams = {
  code: string;
  password: string;
  passwordConfirmation: string;
};

export interface Location {
  province: string;
  id: number;
  city: string;
  isPrimary: boolean;
}

export interface UserService {
  experienceLevel: string;
  id: number;
  name: string;
  rateHour?: number;
  locale?: string;
  localizations?: UserService[];
}
export interface UserIndustry {
  id: number;
  name: string;
  popular: boolean;
  locale?: string;
  localizations?: UserIndustry[];
}
export interface UserSoftware {
  id: number;
  name: string;
  popular: boolean;
  locale?: string;
  localizations?: UserSoftware[];
}

export interface UserLanguage {
  experienceLevel: string;
  id: number;
  name: string;
  locale?: string;
  localizations?: UserLanguage[];
}
export interface User {
  id: number;
  fullName: string;
  contactName?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  description: string;
  organizeType: string;
  companyName: string;
  companySize: string;
  preCVLPExp: boolean;
  locations: Location[];
  services: UserService[];
  createdAt: string;
  industries: UserIndustry[];
  softwares: UserSoftware[];
  skills: {
    id: number;
    name: string;
  }[];
  linksAndPortfolio: {
    // id: number;
    uri: string;
    type: string;
    displayName?: string;
  }[];
  languages: UserLanguage[];
  role: {
    type: string;
  };
  confirmed: boolean;
  avatar?: StrapiFile;
  isAdminReportsAllowedAccess?: boolean;
  isNonPartnerOrganization?: boolean;
  organizationName?: string;
  organizationContactNumber?: number;
  organizationProvince?: number;
  associatedInstitution?: string;
  prevIndigenousExp?: string;
  subscriptionPlan?: string;
}

export interface UpdateProfileParams
  extends Partial<
    Omit<User, 'industries' | 'softwares' | 'skills' | 'avatar'>
  > {
  step: string;
  industries?: number[];
  softwares?: number[];
  skills?: number[];
  avatar?: number[];
}

export interface UpdateRatesParams {
  ids: number[];
  rateHour: number;
}

export interface CheckEmailExistedParams {
  email: string;
}

export const signupApi = async (params: SignupParams) =>
  api.post('/auth/local/register', params) as Promise<{
    user: User;
    jwt: string;
  }>;

export const loginApi = async (params: LoginParams) =>
  api.post('/auth/local', params) as Promise<{
    user: User;
    jwt: string;
  }>;

export const meApi = async () => api.get('/users/me') as Promise<User>;
export const updateProfileApi = async (params: UpdateProfileParams) =>
  api.post('/auth/update', params) as Promise<{ user: User }>;

export const confirmEmailApi = async (confirmation: string) =>
  api.post('/auth/email-confirmation', { confirmation }) as Promise<{
    user: User;
    jwt: string;
  }>;
export const updateRatesApi = async (params: UpdateRatesParams) =>
  api.post('/auth/update-rates', params) as Promise<{ user: User }>;

export const sendEmailConfirmationApi = async (
  params: SendEmailConfirmationParams
) => api.post('/auth/send-email-confirmation', params);

export const forgotPasswordApi = async (params: SendEmailConfirmationParams) =>
  api.post('/auth/forgot-password', params);

export const resetPasswordApi = async (params: ResetPasswordParams) =>
  api.post('/auth/reset-password', params);

export const checkEmailExistedApi = async (params: CheckEmailExistedParams) =>
  api.post('/auth/check-email', params);

export const updateLanguageApi = async (params: { language: string }) => {
  const response = await api.post('/auth/update-language', params);
  console.log(response);
};
