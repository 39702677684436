import React, { useState } from 'react';
import {
  Dialog,
  Box,
  Typography,
  InputAdornment,
  Alert,
  IconButton,
  TextField,
  Link,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { grey5 } from 'theme/color';
import { useAppDispatch, useSelector } from 'store';
import Button from 'components/Button';
import LinkIcon from 'icons/Link';
import { useTranslation } from 'react-i18next';
import { actions } from 'slices/auth';
import { loginThunk } from 'slices/auth/loginReducer';
import { USER_ROLES } from '../../constants';
import { routesConstant } from '../../constants/routeConstant';
import { User } from 'apis/auth';

const useStyles = makeStyles(() =>
  createStyles({
    termsOfUseLinkIcon: {
      marginLeft: '4px',
    },
  })
);

const REDIRECT_URLS = {
  [USER_ROLES['Vendor(Serviceprovider)']]: routesConstant.dashboard,
  [USER_ROLES['Institution(ProjectLead)']]: routesConstant.dashboard,
  [USER_ROLES['multipleRoles']]: routesConstant.dashboard
};

const LoginModal: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openLoginModal, error, isProcessing } = useSelector(
    (state) => state.auth
  );
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword((pre) => !pre);

  const toggleLoginModal = (open: boolean) => () => {
    dispatch(actions.toggleLoginModal(open));
  };

  const toggleSignupModal = (open: boolean) => () => {
    dispatch(actions.toggleSignupModal(open));
  };

  const toggleForgotPasswordModal = (open: boolean) => () => {
    dispatch(actions.toggleForgotPasswordModal(open));
  };

  return (
    <Dialog
      open={openLoginModal}
      onClose={toggleLoginModal(false)}
      maxWidth="md"
    >
      <Box
        sx={{
          width: 540,
          '@media screen and (max-width: 767px)': {
            width: '100%',
          },
          minHeight: 500,
          display: 'flex',
          flexDirection: 'column',
          pt: (theme) => theme.spacing(4),
          px: (theme) => theme.spacing(5),
          pb: (theme) => theme.spacing(4.5),
        }}
      >
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            <Typography
              variant="h5"
              fontWeight="fontWeightBold"
              color="common.black"
              sx={{ mb: 2, textAlign: 'center' }}
            >
              {t('button.login')}
            </Typography>
            <Formik
              initialValues={{
                identifier: '',
                password: '',
              }}
              validationSchema={Yup.object().shape({
                identifier: Yup.string()
                  .email(t('invalidField', { fieldName: 'Email' }))
                  .required(t('missingField', { fieldName: 'Email' })),
                password: Yup.string().required(
                  t('missingField', { fieldName: 'Password' })
                ),
              })}
              onSubmit={async ({ identifier, password }) => {
                dispatch(
                  loginThunk({
                    identifier,
                    password,
                  })
                ).then((action) => {
                  if (action?.meta?.requestStatus === 'fulfilled') {
                    navigate(
                      REDIRECT_URLS[(action.payload as User).role?.type] ||
                        routesConstant.home
                    );
                  }
                });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <TextField
                    error={Boolean(touched.identifier && errors.identifier)}
                    fullWidth
                    helperText={touched.identifier && errors.identifier}
                    label={t('passwordResetEmailLabel')}
                    margin="normal"
                    name="identifier"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.identifier}
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    label={t('enterPassword')}
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            tabIndex={-1}
                            aria-label="toggle password visibility"
                            onClick={toggleShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    sx={{ mt: 3, textTransform: 'none', padding: 0 }}
                    color="secondary"
                    variant="text"
                    onClick={toggleForgotPasswordModal(true)}
                  >
                    {t('button.forgot')}
                  </Button>
                  {error && (
                    <Box sx={{ mt: 2 }}>
                      <Alert severity="error">{t(error)}</Alert>
                    </Box>
                  )}
                  <Button
                    sx={{ mt: 2, textTransform: 'none' }}
                    loading={isProcessing}
                    fullWidth
                    color="secondary"
                    variant="contained"
                    type="submit"
                  >
                    {t('button.login')}
                  </Button>
                  <Typography
                    sx={{
                      margin: '20px auto 0px',
                      color: grey5,
                      textAlign: 'center',
                    }}
                    variant="body2"
                    component="div"
                  >
                    {`${t('agreeTermsAndService')} `}
                    <Link
                      underline="none"
                      // href={routesConstant.termsOfUse}
                      href={t('termsLink')}
                      target="blank"
                      color="secondary.main"
                      fontWeight="600"
                    >
                      <Box
                        sx={{
                          display: 'inline-flex',
                          flexDirection: 'row',
                          gap: '3px',
                        }}
                      >
                        {t('termsOfService')}
                        <div className={classes.termsOfUseLinkIcon}>
                          <LinkIcon />
                        </div>
                      </Box>
                    </Link>
                  </Typography>
                </form>
              )}
            </Formik>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="3px"
            mt={4}
          >
            <Typography variant="body1" fontWeight="600">
              {t('notRegistered')}
            </Typography>
            <Button
              variant="text"
              color="secondary"
              sx={{
                padding: 0,
                textTransform: 'none',
              }}
              onClick={toggleSignupModal(true)}
            >
              {t('button.signUp')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default LoginModal;
