import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  TextField,
  FormHelperText,
  ListSubheader,
  MenuItem,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { SignupParams } from 'apis/auth';
import { actions } from 'slices/auth';
import { useAppDispatch, useSelector } from 'store';
import { checkEmailExistedThunk } from 'slices/auth/checkEmailExistedReducer';
import { SIGNUP_STEPS } from '../../../constants';
interface Props {
  onChange: (formData: SignupParams, nextStep: string) => void;
}

const UserForm: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [emailError, setEmailError] = useState('');
  const [isContinue, setIsContinue] = useState(false);

  useEffect(() => {
    setIsContinue(false);
  }, [emailError]);

  const toggleLoginModal = (open: boolean) => () => {
    dispatch(actions.toggleLoginModal(open));
  };

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
      }}
      validationSchema={Yup.object().shape({
        firstName:
          Yup.string()
            .trim()
            .required(t('missingField', { fieldName: 'First name' }))
        ,
        lastName:
          Yup.string()
            .trim()
            .required(t('missingField', { fieldName: 'Last name' })),
        email: Yup.string()
          .trim()
          .email(t('invalidField', { fieldName: 'Email' }))
          .required(t('missingField', { fieldName: 'Email' })),
      })}
      onSubmit={async (values) => {
        dispatch(checkEmailExistedThunk(values.email)).then((action) => {
          if (action?.meta?.requestStatus === 'fulfilled') {
            setEmailError('');
            isContinue &&
              onChange(values as SignupParams, SIGNUP_STEPS.RoleForm);
          } else {
            setEmailError(t('emailError.message'));
          }
        });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <>
            <TextField
              error={Boolean(touched.firstName && errors.firstName)}
              fullWidth
              helperText={touched.firstName && errors.firstName}
              label={t('firstName')}
              margin="normal"
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.lastName && errors.lastName)}
              fullWidth
              helperText={touched.lastName && errors.lastName}
              label={t('lastName')}
              margin="normal"
              name="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
              variant="outlined"
            />
          </>
          <TextField
            error={Boolean((touched.email && errors.email) || emailError)}
            fullWidth
            helperText={
              (touched.email && errors.email) ||
              (emailError && (
                <FormHelperText sx={{ marginX: 0 }} component="span">
                  {emailError}{' '}
                  <Button
                    variant="text"
                    color="secondary"
                    sx={{
                      padding: 0,
                      fontSize: '12px',
                      textTransform: 'none',
                      minWidth: 'fit-content',
                    }}
                    onClick={toggleLoginModal(true)}
                  >
                    {t('button.login')}
                  </Button>
                </FormHelperText>
              ))
            }
            label={t('passwordResetEmailLabel')}
            margin="normal"
            name="email"
            onBlur={() => handleSubmit()}
            onChange={(e) => {
              handleChange(e);
              setEmailError('');
            }}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <Button
            sx={{ mt: 5, textTransform: 'none' }}
            fullWidth
            color="secondary"
            variant="contained"
            type="submit"
            onClick={() => setIsContinue(true)}
          >
            {t('button.continue')}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default UserForm;
