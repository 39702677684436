import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import NavItem from './NavItem';
import NavDropDownItem from './NavDropDownItem';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

// @ts-ignore
const computeNavItems = (t: ReturnType<typeof useTranslation>['t'],language: string) => {
  return [
    { text: t('navigation.about'), pathname: '/#about', ind: 1 },
    { text: t('navigation.forEducators'), pathname: '/#howitworks', ind: 2  },
    { text: t('navigation.forProfessionals'), pathname: '/#vendors', ind: 3  },
    { text: t('navigation.impact'), pathname: '/#impact', ind: 4  },
    {
      text: t('navigation.Others'),
      pathname: '/',
      dropdown: true,
      dropdownProps: [
        {
          text: t('navigation.pricing'), //Pricing
          path: language === 'fr' ? 'https://www.ecampusontario.ca/fr/ontarioexchange/tarification/ ' : 'https://www.ecampusontario.ca/ontarioexchange/pricing',
          ind: 6
        },
        {
          text: t('navigation.GetStarted'), //UserGuide
          path: language === 'fr' ?  'https://www.ecampusontario.ca/fr/ontarioexchange/getting-started/' : 'https://www.ecampusontario.ca/ontarioexchange/getting-started',
          ind: 7 
        },
        {
          text: t('navigation.FAQ'), //FAQ
          path: language === 'fr' ?'https://www.ecampusontario.ca/fr/ontarioexchange/faq/': 'https://www.ecampusontario.ca/ontarioexchange/faq',
          ind: 8 
        },
        {
          text: t('navigation.oexDocuments'), //OEX Documents
          path: language === 'fr' ?'https://www.ecampusontario.ca/fr/ontarioexchange/oex-documents/':'https://www.ecampusontario.ca/ontarioexchange/oex-documents',
          ind: 9 
        },
      ],
      ind: 10 
    },
    {
      text: t('navigation.ContactUs'),
      pathname: 'mailto:exchange@ecampusontario.ca',
      ind: 1 
    },
  ];
};

const MainNavbar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const language = localStorage.getItem('i18nextLng')||i18n.language;
  const navItems = React.useMemo(() => {
    return computeNavItems(t,language);
  }, [t]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
        marginX: 1,
      }}
    >
      {navItems.map((item) => (
        <React.Fragment>
          {item.dropdown ? (
            <NavDropDownItem
              dropdownProps={item.dropdownProps}
              key={item.ind}
              pathname={item.pathname}
              active={
                Boolean(location.hash) && item.pathname.endsWith(location.hash)
              }
            >
              <h4>{item.text}</h4>
            </NavDropDownItem>
          ) : (
            <NavItem
              key={item.ind}
              pathname={item.pathname}
              active={
                Boolean(location.hash) && item.pathname.endsWith(location.hash)
              }
            >
              <h4>{item.text}</h4>
            </NavItem>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};
export default MainNavbar;
